body{
  height:100vh;
 
  
}

.mylogo{
  width:120px;
  border-radius: 100%;
}

.myhead{
  font-size: 17px;
  font-weight: 700;
  font-family:Arial, Helvetica, sans-serif;
}


.label{
  font-size: 14px;
  font-weight: 500;
}

.btnstyle{
  background: #ff9017;
  color:white;
}

.show{
  color:#1997cf;
  font-size:14px;
  font-weight: bold;
}

.small{
  font-size: 13px;
}